<template>
<div id="inside">
  <div class="container">
      <div class="row">
        <div class="col-12 text-body px-2 mb-2">
          <button class="btn justify-content-left text-decoration-none btn-link flex-row d-flex align-items-center pr-2 pb-2 pl-1" @click="home()"><i class="fa fa-chevron-left fa-fw pull-left text-info"></i></button>
        </div>
      </div>
      <div class="row" v-if="cardOffers && !loadOffers">
        <div class="col-12 text-body pr-0">
          <h4 class="mb-1 text-primary d-flex flex-row align-items-center">Negociar</h4>
          <h5 class="mb-1 d-flex flex-row align-items-center" v-if="agreementInfo">{{ agreementInfo.product }}</h5>
          <p class="mb-1">Valor total da dívida:</p>
          <h5 class="mb-3 text-danger">{{ formatFloatToReal(parseFloat(agreementInfo.originalValue, 10)) }}</h5>
          <HeaderOptions/>
          <p class="">Escolha uma das opções:</p>
        </div>
      </div>
    </div>
    <div class="container" v-if="!cardOffers && !loadOffers">
      <div class="row">
        <div class="align-items-center justify-content-center col-sm-12 col-12 d-flex col-md-12 col-lg-12 px-4"><i class="fa fa-2x fa-exclamation-circle pull-left mr-4 mb-2 text-primary"></i>
          <h4 class="py-4 text-primary">No momento não temos uma oferta disponível para você. </h4>
        </div>
      </div>
      <div class="row pb-5">
        <div class="align-items-center justify-content-center col-sm-12 col-12 d-flex col-md-12 col-lg-12 px-4">
          <h6 class="py-2 text-muted">Conte com a gente para ter um alívio financeiro! É só selecionar o produto e acionar o atendimento que preferir:</h6>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12 col-lg-3 px-4">
          <h4 class="py-4">Central de Relacionamento Cartão de Crédito </h4>
          <p><a href="tel:40043600" target="_blank">4004 3600</a> - Capitais e regiões metropolitanas.&nbsp;<br><a href="tel:08007277477" target="_blank">0800 727 7477</a> - Demais localidades.&nbsp;<br><a href="tel:551133663216" target="_blank">+55 11 3366 3216</a> - Atendimento no exterior.&nbsp;<br>De segunda a sexta-feira, das 8h às 18h30.</p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">Central de Relacionamento Cartão de Crédito Empresarial </h4>
          <p><a href="tel:1140043600" target="_blank">4004 3600</a> - Capitais e regiões metropolitanas.<br><a href="tel:08007277477" target="_blank">0800-727-7477</a> - Demais localidades.&nbsp;<br>De segunda à sexta das 7h40 às 19h00.</p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">Central de Relacionamento Empréstimo e Financiamento </h4>
          <p><a href="tel:40045215" target="_blank">4004 5215</a>&nbsp;<br>De segunda a sexta-feira, das 8h às 19h (exceto feriados).</p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">SAC</h4>
          <p><a href="tel:08007272745" target="_blank">0800 727 2745</a><br>Atendimento 24 horas.</p>
        </div>
      </div>
    </div>

    <div class="container" v-if="cardOffers && !loadOffers" :style="largura < 500 ? 'padding-left: 25px;' : ''">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4" v-for="(value, key, index) in cardOffers.paymentOptions" :key="`${key}-${index}`">
          <div class="card" :style="largura < 500 ? 'min-height: 323px !important; max-height: 300px !important;' : 'min-height: 258px !important;'">
            <div class="card-body pb-2 border-top-0 py-2">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <h5 class="text-muted" v-if="value.installmentsNumber === 1">Pagamento à vista</h5>
                  <h5 class="text-muted" v-if="value.installmentsNumber !== 1">Parcelado em {{ value.installmentsNumber }} vezes</h5>
                  <br v-if="!Math.sign(Math.floor(((parseFloat(value.value, 10) / parseFloat(agreementInfo.paymentValue, 10) -1) * 100) * -1).toFixed(0)) > 0">
                  <h6 v-if="Math.sign(Math.floor(((parseFloat(value.value, 10) / parseFloat(agreementInfo.paymentValue, 10) -1) * 100) * -1).toFixed(0)) > 0"
                    class="rounded small bg-success py-1 px-2 float-left text-white">
                    {{ Math.floor(((parseFloat(value.value, 10) / parseFloat(totalInstallmentsCdcWithFees, 10) -1) * 100) * -1).toFixed(0) }}
                    % de desconto
                  </h6>
                </div>
              </div>
              <div class="row mt-0 pb-1">
                <div class="col-xs-8 col-6 col-lg-6">
                  <p class="mb-1 small">Total de parcelas selecionadas:</p>
                  <h5 class="text-dark small">{{ agreementInfo.installments.length }}</h5>
                </div>
                <div class="col-xs-8 col-6 col-lg-6">
                  <p class="mb-1 small">Valor total selecionado:</p>
                  <h5 class="text-dark small">{{ formatFloatToReal(parseFloat(sumTotalInstallmentsCdc, 10)) }}</h5>
                </div>
              </div>
              <div class="row mt-0 pb-1">
                <div class="col-xs-8 col-12 col-lg-12">
                  <p class="mb-1 small">Valor total a negociar (+ juros / multa):</p>
                  <h5 class="text-dark small">{{ formatFloatToReal(parseFloat(totalInstallmentsCdcWithFees, 10)) }}</h5>
                </div>
              </div>
              <div class="row mt-0">
                <div class="col-xs-8 col-12 col-lg-12">
                  <p class="mb-1 small">Valor total do acordo:</p>
                  <h5 class="text-primary">{{ formatFloatToReal(parseFloat(value.value, 10)) }}</h5>
                </div>
              </div>
              <div v-bind:class="[ largura < 500 ? 'row' : 'row' ]" v-if="value.installmentsNumber !== 1">
                <div class="col-xs-8 col-lg-12 col-12">
                  <p class="mb-1 small">Valor com desconto:</p>
                  <h6 class="small text-primary">{{ formatFloatToReal(parseFloat(value.value, 10)) }}</h6>
                </div>
              </div>
              <div class="row mt-0" v-if="value.installmentsNumber !== 1">
                <div class="col-xs-8 col-lg-6 col-12 mt-5">
                  <p class="mb-1 small">Valor de entrada:</p>
                  <h6 class="text-dark small">{{ formatFloatToReal(parseFloat(value.inputValue, 10)) }}</h6>
                </div>
                <div class="col-xs-8 col-lg-6 col-12 mt-2 pl-3">
                  <p class="mb-1 small">Valor da parcela:</p>
                  <h6 class="text-dark small">{{ formatFloatToReal(parseFloat(value.installmentValue, 10)) }}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-2 mt-3 col-lg-12 col-12 text-center">
                  <button class="btn mr-1 mb-1 btn-primary" :disabled="loadOffers || loadingMoreOptions" @click="resumoAcordo(value, false)">Negociar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadOffers">
      </div>
    </div>
    <div class="container" v-if="loadOffers || loadingMoreOptions">
      <ProgressLoading v-bind:loading="loadOffers || loadingMoreOptions" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData, truncateString } from "@/utils/helpers";
import ProgressLoading from "../../../modules/auth/components/ProgressLoading.vue";
import HeaderOptions from "../components/HeaderMenu.vue"
import {VMoney} from 'v-money';
import { initializeGlobals } from '@/global';


export default {
  name: "propostas-cdc",
    components: {
    ProgressLoading,
    HeaderOptions,
  },
  directives: {
      money: VMoney
    },
  data () {
      return {
        form: {
          offerSelected: null,
        },
        loadOffers: false,
        loadOffersWithEntry: false,
        offers: [],
        agreementInfo: [],
        largura: null,
        installment: {},
        loadSetAgreement: false,
        cardOffers: null,
        otherOptionsInstallment: null,
        newOffersLoaded: false,
        othersOptionsLoaded: false,
        selectedOption: {},
        selectedOptionEntryValue: {},
        paymentOptionsId: [],
        isContencioso: false,
        entryValue: null,
        money: {
              decimal: ',',
              thousands: '.',
              prefix: 'R$ ',
              suffix: '',
              precision: 2,
              masked: false /* doesn't work with directive */
            },
        responseWithEntryValue: [],
        entryValueFinal: null,
        loadingMoreOptions: false,
        lastOptionCalled: null,
        count: 0,
        userDataStorage: {},
        pageLocation: null,
        protocol: null,
        path: null,
        host: null,
        userAdm: {},
        ipUser: null

      }
  },
  methods: {
    formatValue,
    formatData,
    truncateString,

    ...mapActions("meusContratos", ["ActionGetOffersPortoSeguro",]),

    formatFloatToReal(value) {
      return value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
    },

    async loadParcelamentoFatura() {
      this.loadOffers = true;
      if(this.$route.params.from === 'resumoAcordo') {
        this.agreementInfo = this.$route.params.agreementInfo;
      } else {
        this.agreementInfo = this.$route.params.agreementInfo;
      }

    try {

      const infoInstallments = this.agreementInfo.installments.map((value) => {
        return {
            dueDate: value.dueDate,
            installmentNumber: value.installmentNumber,
        };
      });

      var result = await this.ActionGetOffersPortoSeguro({
          clientCode: this.agreementInfo.contract,
          credor: this.agreementInfo.credor,
          installmentsOptions: [1],
          agreementType: "ParcelamentoCDC",
          contract: this.agreementInfo.contract,
          discount: 0,
          inputValue: 0,
          paymentType: "Boleto",
          contracts: [
            {
              contract: this.agreementInfo.contract,
              product: this.agreementInfo.product,
              installments: infoInstallments
            }
          ],
          tag: localStorage.getItem('tag'),
          daysDelay: this.agreementInfo.daysDelay,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS:navigator.platform,
          IP: this.ipUser ? this.ipUser : '',
        });

        this.cardOffers = result.data.results;

    } catch (error) {
      this.flashMessage.show({
        time:3000,
        status: 'error',
        position:'top right',
        title: 'Erro',
        message: 'Não foi possível buscar ofertas, tente novamente mais tarde!'
      });
      this.eventCallbackNegociarFailureGTM(error);
      // this.$router.push({
      //   name: "dashboard",
      // });
    } finally {
      this.loadOffers =  false;
    }
    },

    async resumoAcordo(offer, isWithEntryValue) {
      
      if(offer === null) {
        offer = this.selectedOption;
      }
      this.eventClickNegociarGTM(offer);

      this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/resumoAcordo', 'resumo-acordo');
      this.updateCustomData();
      this.$router.push({
        name: "resumoAcordo",
        params: {
          offerInfo: {
            clientCode: this.agreementInfo.clientCode,
            credor: this.agreementInfo.credor,
            product: this.agreementInfo.product,
            dueDate: this.cardOffers.dueDate,
            contract: this.agreementInfo.contract,
            paymentOptionCode: this.cardOffers.paymentOptionCode,
            paymentOptionsCodeIds: this.paymentOptionsId,
            paymentValue: this.agreementInfo.paymentValue,
            entryValue: isWithEntryValue ? this.entryValueFinal : 0,
            agreementType: "ParcelamentoCDC",
            ...offer
          },
          agreementInfo: this.agreementInfo,
          fromProposals: true,
          from: 'propostasCdc',
          isWithEntryValue: isWithEntryValue,
          sumTotalInstallmentsCdc: this.sumTotalInstallmentsCdc,
          totalInstallmentsCdcWithFees: this.totalInstallmentsCdcWithFees,

        }
      });
    },

    home() {
      this.eventClickArrowToBackGTM();
      this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard', 'Dashboard');
      this.updateCustomData();
      this.$router.push({
        name: "dashboard",
      });
    },

    buildAndSetUserData(doc, id, pageLocation, pageName) {
      // const pageName = this.$options.name;
      const userData = {
                      cpfCnpj: doc, // Substitua pelo CPF/CNPJ real
                      id: id, // Substitua pelo ID real
                      page_location: pageLocation,
                      pageName: pageName
                    };
                    localStorage.setItem("userData", JSON.stringify(userData));
    },

    eventClickArrowToBackGTM() {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = 'click:button:voltar';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: '',
          };

          console.log("eventData ClickArrowToBack PROPOSTASCDC: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },


    eventClickNegociarGTM(offer) {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = 'click:button:negociar';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: this.buildPayloadOfferChoose(offer),
            product_identify: this.agreementInfo.contract,
            product_category: this.agreementInfo.product,
            product_user_profile: 'titular',
            brand: 'porto',
            product: this.agreementInfo.product,
            vertical: 'bank',
          };

          console.log("eventData ClickNegociar PROPOSTASCDC: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },


    buildPayloadOfferChoose(offer) {
      var formatedPayload = null;
      if(offer.installmentsNumber === 1) {
        formatedPayload = 'pagamento-a-vista';
      } else {
        formatedPayload = `parcelado-em-${offer.installmentsNumber}-vezes`;
      }
      return formatedPayload;
    },

    eventCallbackNegociarFailureGTM(error) {
                    console.log('errorrrrr: ' + JSON.stringify(error.status));
                    const pageName = this.$options.name;
                    const eventCategory = `portal-de-negociacao:porto:${pageName}`;
                    const eventAction = 'solicitar-opcoes-pagamento:alert';
                    const eventLabel = truncateString('Não foi possível buscar ofertas, tente novamente mais tarde!', 100);
                    const eventCode = error.status;
                    const eventErrorService = '';
                    const eventAlertService = truncateString(error.data.message, 100);
                    const eventAlert = 'select_content';
                    const eventAlertType = 'erro';
                    const eventData = {
                      ev_category: eventCategory,
                      ev_action: eventAction,
                      ev_label: eventLabel,
                      alert_code: eventCode,
                      error_service: eventErrorService,
                      alert_service_message: eventAlertService,
                      alert_event: eventAlert,
                      alert_type: eventAlertType,
                    };

                    console.log("eventData CLICK NEGOCIAR ERRO: " + JSON.stringify(eventData))

                    window.dataLayer.push({ event: 'alert', ...eventData });
    },

    updateCustomData() {
      const globals = initializeGlobals();
      window.customData = globals.customData;
    }


  },
  async created() {
    this.updateCustomData();
    this.ipUser = localStorage.getItem('ipUser');
    localStorage.setItem("currentPageName", this.$options.name);
    this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
    this.userAdm = JSON.parse(localStorage.getItem("userAdm"));
    this.protocol = window.location.protocol;
    this.host = window.location.host;
    this.path = window.location.pathname;
    this.pageLocation = this.protocol + '//' + this.host + this.path;
    this.largura = window.screen.width;
    this.agreementInfo = this.$route.params.agreementInfo;
    this.sumTotalInstallmentsCdc = this.$route.params.sumTotalInstallmentsCdc;
    this.totalInstallmentsCdcWithFees = this.$route.params.totalInstallmentsCdcWithFees;

    this.loadParcelamentoFatura();

  },
  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  }
};
</script>

<style scoped>
@media(max-width: 500px) {
    .card {
        margin-right: 0;
    }
}
</style>
